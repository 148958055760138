/*================================================================================
	Item Name: TRANZAXION
	Version: 2.0
	Author: Dima-Tech
	Author URL: http://www.Dima-Tech.com
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "@styles/base/bootstrap-extended/include";
@import "@styles/base/components/include";
// InValid Select
.description-truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sticky-action-card {
  position: sticky;
  top: 106px;
}

//** switch-dark-mode
.switch-theme {
  width: 70px;
  height: 30px;
  margin-left: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  background: $body-bg;
  border-radius: 20px;
  box-shadow:
    inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}
.switch-theme:after {
  content: "";
  width: 28px;
  height: 28px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 18px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.input-switch {
  width: 0;
  height: 0;
  visibility: hidden;
}
.input-switch:checked + .switch-theme {
  background: $theme-dark-body-bg;
}
.input-switch:checked + .switch-theme:after {
  left: 69px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.switch-theme:active:after {
  width: 38px;
}
.switch-theme svg {
  align-self: center;
  font-size: 18px;
  z-index: 100;
}
.switch-theme svg.sun {
  margin-left: 5px;
  color: #7e7e7e;
  transition: 0.3s;
}
.switch-theme svg.moon {
  margin-right: 8px;
  color: transparent;
  transition: 0.3s;
}
.input-switch:checked + .switch-theme svg.sun {
  color: transparent;
}
.input-switch:checked + .switch-theme svg.moon {
  color: #7e7e7e;
}

//** card-shadow 3d
.card-shadow {
  position: relative;
  //width: 300px;
  height: 25rem;
  //transform-style: preserve-3d;
  //background-image: linear-gradient(45deg, #fff, rgba(255,255,255,.75), rgba(255,255,255,.5));
  border-radius: 1rem;
  //border: 3px solid #ffffff;
  box-shadow:
    0 1px 0 #ccc,
    0 2px 0 #c9c9c9,
    0 3px 0 #bbb,
    0 0px 0 #b9b9b9,
    0 5px 0 rgba(125, 125, 125, 0.2),
    0 6px 1px rgba(130, 130, 130, 0.2),
    0 0 5px rgba(154, 154, 154, 0.2),
    0 1px 3px rgba(114, 114, 114, 0.2),
    0 3px 5px rgba(164, 164, 164, 0.2),
    0 5px 10px rgba(135, 135, 135, 0.2),
    0 10px 10px rgba(198, 198, 198, 0.2),
    0 20px 20px rgba(136, 136, 136, 0.2),
    0 0 4px white,
    0 0px 3px white,
    1px 1px 5px rgba(206, 206, 206, 0.37),
    1px 1px 10px rgba(123, 123, 123, 0.5);
}
.card-shadow:dark {
  position: relative;
  //width: 300px;
  height: 25rem;
  transform-style: preserve-3d;
  //background-image: linear-gradient(45deg, #fff, rgba(255,255,255,.75), rgba(255,255,255,.5));
  border-radius: 1rem;
  //border: 3px solid #ffffff;
  box-shadow:
    0 1px 0 #ccc,
    0 2px 0 #c9c9c9,
    0 3px 0 #bbb,
    0 0px 0 #b9b9b9,
    0 5px 0 rgba(125, 125, 125, 0.2),
    0 6px 1px rgba(130, 130, 130, 0.2),
    0 0 5px rgba(154, 154, 154, 0.2),
    0 1px 3px rgba(114, 114, 114, 0.2),
    0 3px 5px rgba(164, 164, 164, 0.2),
    0 5px 10px rgba(135, 135, 135, 0.2),
    0 10px 10px rgba(198, 198, 198, 0.2),
    0 20px 20px rgba(136, 136, 136, 0.2),
    0 0 4px white,
    0 0px 3px white,
    1px 1px 5px rgba(206, 206, 206, 0.37),
    1px 1px 10px rgba(123, 123, 123, 0.5);
}

//**
.card-profile-top {
  background-image: url("../images/banner/banner.png");
  height: 7rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

//** Loading
.input-loading {
  display: none;
}

.input-loading:checked ~ label {
  animation: none;
  border: 4px solid $primary;
  transition: border 0.5s ease-out;
}

.input-loading:checked ~ label .check {
  display: block;
}

.label-spinner {
  width: 5rem;
  height: 5rem;
  position: relative;
  border-radius: 50%;
  border: 4px solid #7267f058;
  border-left: 4px solid $primary;
  animation: spin 1.2s linear infinite;
}

.label-spinner .check {
  top: 50%;
  left: 17px;
  width: 20px;
  content: "";
  height: 40px;
  display: none;
  position: absolute;
  transform-origin: left top;
  animation: check 0.8s ease;
  border-top: 4px solid $primary;
  border-right: 4px solid $primary;
  transform: scaleX(-1) rotate(135deg);
}

.button-spinner {
  display: none;
}
.input-loading:checked ~ button {
  display: block;
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 20px;
    opacity: 1;
  }

  40% {
    height: 40px;
    width: 20px;
    opacity: 1;
  }

  100% {
    height: 40px;
    width: 20px;
    opacity: 1;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
